const getDefaultMetadata = () => {
		return {
				// Project name
				title: "",
				// Which dedupe strategy to use
				// strategy: "forbesMinFN"
				strategy:"forbesMinFP"
		}
}

const state = {
		metadata: getDefaultMetadata()
}

const getters = {
	// Get the title
	getTitle: state => {
		return state.metadata.title;
	},
	// Get strategy
	getStrategy: state => {
		return state.metadata.strategy;
	}
}

const mutations = {
	reset(state) {
		state.metadata = getDefaultMetadata();
	},
	// Set metadata
	setMetadata(state, data) {
		// Set metadata
		Object.assign(state.metadata, data);
	},
	// Set title
	setTitle(state, title) {
		// Set title
		state.metadata.title = title;
	},
	// Set strategy
	setStrategy(state, strategy) {
		// Set strategy
		state.metadata.strategy = strategy;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
