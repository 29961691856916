import { mapState } from 'vuex';
import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	computed: {
		...mapState({
			metadata: state => state.metadata.metadata,
			refs: state => state.references.refs,
			dupes: state => state.duplicates.dupes
		}),
	},
	methods: {
		async save() {

			const metadata = this.metadata;
			const refs = this.refs;
			const dupes = this.dupes;

			const saveObject = {
				metadata,
				refs,
				dupes
			}

			const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(saveObject));
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute("href", dataStr);
			downloadAnchorNode.setAttribute(
				"download",
				"ddpe_"
					+ this.getProjectName()
					+ " "
					+ this.getDateString()
					+ ".ddpe"
			);
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	}
}