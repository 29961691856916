<template>
	<div id="app">
		<!-- NAVBAR -->
		<b-navbar type="dark" variant="dark" sticky>
			<b-navbar-brand href="#" variant="light">
				Deduplicator
			</b-navbar-brand>
			<b-navbar-nav class="ml-auto">
				<b-button class="mr-2" @click="openHelp" variant="info">Help</b-button>
				<b-button v-if="dupes" @click="close">Choose another library...</b-button>
			</b-navbar-nav>
		</b-navbar>

		<!-- Toolbar -->
		<div class="sticky-toolbar">
			<TheToolbar />
		</div>

		<!-- Spacer to push down content (to prevent content from going underneath title/toolbar) -->
		<div class="spacer">
			&nbsp;
		</div>

		<!-- FILEUPLOAD -->
		<template v-if="!dupes">
			<b-container class="file-container">
				<FileUpload />
				<!-- <b-button class="mt-3" variant="info" @click="loadDemo">Click here to use demo library</b-button> -->
			</b-container>
		</template>

		<!-- LOADING Progress -->
		<div v-if="isLoading" class="m-3 p-2 border border-secondary rounded">
			Import Progress:
			<b-progress :value="progress.parsing" :max="1" show-progress animated></b-progress>
			<div v-if="!(progress.parsingScreened===null)">
				Screened Import Progress:
				<b-progress
					:value="progress.parsingScreened"
					:max="1"
					show-progress animated
				></b-progress>
			</div>
			Deduplication Progress:
			<b-progress :value="progress.deduping" :max="1" show-progress animated></b-progress>
			Total:
			<b-progress v-if="!(progress.parsingScreened===null)" :max="1" show-progress>
				<b-progress-bar :value="progress.parsing * 0.4" variant="success"></b-progress-bar>
				<b-progress-bar :value="progress.parsingScreened * 0.4" variant="info"></b-progress-bar>
				<b-progress-bar :value="progress.deduping * 0.2" variant="warning"></b-progress-bar>
			</b-progress>
			<b-progress v-else :max="1" show-progress>
				<b-progress-bar :value="progress.parsing * 0.8" variant="success"></b-progress-bar>
				<b-progress-bar :value="progress.deduping * 0.2" variant="warning"></b-progress-bar>
			</b-progress>
		</div>

		<!-- TABLE -->
		<template v-if="dupes">
			<DupesArea />
		</template>

		<!-- Fixed footer which is used to display extra reference information -->
		<div v-if="showEditReference" id="fixed-footer" class="resizable">
			<div id="handle"></div>
			<EditReference :id="editReference" />
		</div>

		<!-- Warning Modal -->
		<WarningModal />
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import FileUpload from './components/FileUpload.vue'
import WarningModal from './components/WarningModal.vue'
import DupesArea from './components/DupesArea.vue'
import TheToolbar from './components/TheToolbar.vue'
import EditReference from './components/EditReference.vue';

export default {
	name: 'App',
	components: {
		FileUpload,
		WarningModal,
		DupesArea,
		TheToolbar,
		EditReference
	},
	computed: {
		...mapGetters('duplicates', {
			dupes: 'getDupes'
		}),
		...mapGetters('metadata', {
			title: 'getTitle'
		})
	},
	data() {
		return {
			isLoading: false,
			isEditTitle: false,
			showEditReference: false,
			editReference: null,
			progress: {
				parsing: 0,
				parsingScreened: null,
				deduping: 0
			}
		}
	},
	mounted() {
		// Set loading status
		this.$root.$on('set-loading', isLoading => this.isLoading = isLoading);
		// Set progress
		this.$root.$on('set-progress', progressObj => {
			this.progress[progressObj.stage] = progressObj.progress
		});
		// Show edit footer
		this.$root.$on('show-edit', id => {
			this.editReference = id;
			this.showEditReference = true;
			// Resizable bottom fixed div
			this.$nextTick(function () {
				var handle = document.querySelector("#handle");
				var div = document.querySelector(".resizable");
				var divElm;
				var startOffset;

				handle.style.cursor = 'row-resize';
				handle.addEventListener('mousedown', function(e) {
					e.preventDefault();
					divElm = div;
					startOffset = div.offsetHeight - (window.innerHeight - e.pageY);
				})

				document.addEventListener('mousemove', function (e) {
					if (divElm) {
						if (startOffset + (window.innerHeight - e.pageY) < 200) {
							divElm.style.height = "200px";
						} else if (startOffset + (window.innerHeight - e.pageY) > (window.innerHeight * 0.9)) {
							divElm.style.height = window.innerHeight * 0.9 + 'px';
						} else {
							divElm.style.height = startOffset + (window.innerHeight - e.pageY) + 'px';
						}
					}
				}, { passive: true });

				document.addEventListener('mouseup', function () {
					divElm = undefined;
				});
			});
		});
		// Hide edit footer
		this.$root.$on('hide-edit', () => {
			this.showEditReference = false;
			this.editReference = null;
		});
	},
	methods: {
		close() {
			// this.$root.$emit('show-warning-modal');
			this.$store.dispatch('resetStore');
		},
		openHelp() {
			window.open("https://sr-accelerator.com/#/help/deduplicator", "_blank");
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.$refs['title-edit'].value);
			this.isEditTitle = false;
		},
	}
}
</script>

<style lang="scss">
@import 'theme.scss';

#app {
	min-height: 100vh;
	background-color: $gray-300;
}

.table-area {
	height: calc(100vh - 100px);
}

.sticky-toolbar {
	position: fixed;
	z-index: 104;
	width: 100%;
}

/* Footer css */
#fixed-footer {
	bottom: 0px !important;
	height: 50vh;
	overflow-y: scroll;
	position: fixed;
	z-index: 100;
	width: 100%;
}

/* Footer handle css */
#handle {
	position: sticky;
	top: 0px;
	left: 0px;
	width: calc(100% - 70px);
	height: 40px;
	z-index: 102;
	margin-bottom: -40px;
}

.navbar {
	background-image: linear-gradient(#6880b5, #465b8b, #5971a5);
	/* background-image: linear-gradient(#7992c9, #5876b7, #547acd); */
	/* background-image: linear-gradient(#7e94c6, #5970a1, #6b8cd5); */
	color: #dcdee5;
}
</style>
