import Vue from 'vue';

const state = {
	// Stores refs object keyed by ID
	refs: null,
}

const getters = {
	// Get the entire refs Object
	getRefs: state => {
		return state.refs;
	},
	// Get a specific ref
	getRef: state => id => {
		return state.refs[id];
	}
}

const mutations = {
	reset(state) {
		state.refs = null;
	},
	// Set all refs
	setRefs(state, refs) {
		// Freeze references to improve heap memory usage
		Object.keys(refs).forEach(key => refs[key] = Object.freeze(refs[key]));
		// Set refs
		state.refs = refs;
	},
	// Update individual reference
	updateRef(state, { id, ref }) {
		Vue.set(state.refs, id, ref);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}