import { mapGetters } from "vuex";

let importWorker = new Worker('../workers/importWorker.js', { type: 'module' });

export default {
	computed: {
		...mapGetters({
			strategy: 'metadata/getStrategy'
		})
	},
	mounted() {
		importWorker.onmessage = event => {
			// If finished
			if (event.data.refs) {
				console.log("Worker finished!");
				// Free up worker memory, caveat is if the user is offline, then worker wont reload
				if (window.navigator.onLine) {
					importWorker.terminate();
					importWorker = new Worker('../workers/importWorker.js', { type: 'module' });
				}
				// Give time for progress bar to complete
				setTimeout(() => {
					this.$root.$emit('set-loading', false);
					this.$store.commit('references/setRefs', event.data.refs);
					this.$store.commit('duplicates/setDupes', event.data.dedupedRefs);
				}, 1000)
			}
			// If progress
			else {
				this.$root.$emit('set-progress', event.data);
			}
		}
		importWorker.onerror = err => {
			console.log(err);
			if (err.message) {
				alert("Error:" + err.message);
			} else {
				alert("Error:", err);
			}
			// Set loading to false
			this.$root.$emit('set-loading', false);
		}
	},
	methods: {
		importFiles(files, screenedFile = null) {
			/* eslint-disable */
			// Parse both files
			if (screenedFile && files) {
				this.$root.$emit('set-progress', { stage: "parsing", progress: 0 });
				this.$root.$emit('set-progress', { stage: "parsingScreened", progress: 0 });
				this.$root.$emit('set-progress', { stage: "deduping", progress: 0 });
				this.$root.$emit('set-loading', true);
				console.log("Sending refs to worker...");

				return Promise.all([
					screenedFile && screenedFile.getRefs(),
					...files.map(file => file.getRefs())
				])
					.then(([screenedRefs, ...refs]) => importWorker.postMessage({
						strategy: this.strategy,
						refs: refs.flat(1),
						screenedRefs,
					}));
			}
			// Parse just dedupe file
			else if (files) {
				this.$root.$emit('set-progress', { stage: "parsing", progress: 0 });
				this.$root.$emit('set-progress', { stage: "parsingScreened", progress: null });
				this.$root.$emit('set-progress', { stage: "deduping", progress: 0 });
				this.$root.$emit('set-loading', true);
				console.log("Sending refs to worker...");

				return Promise.all(files.map(file =>
					file.getRefs()
				))
					.then(refs => importWorker.postMessage({
						strategy: this.strategy,
						refs: refs.flat(1),
						screenedRefs: null,
					}))
			}
			// Else error due to no files
			else {
				alert("Error: No file to import");
				console.error("No file to import");
			}
		},
		getFileExtention(name) {
			return "." + name.split(".").pop();
		}
	}
}
