export default {
	methods: {
		loadFile(file) {
			this.$root.$emit('setLoading', true);
			const reader = new FileReader();
			reader.onload = e => {
				const data = JSON.parse(e.target.result);
				// Load data into dedupe
				if (data.metadata) {
					this.$store.commit('metadata/setMetadata', data.metadata);
				} else {
					this.$store.commit('metadata/reset');
				}
				if (data.refs) {
					this.$store.commit('references/setRefs', data.refs);
				} else {
					this.$store.commit('duplicates/reset');
				}
				if (data.dupes) {
					this.$store.commit('duplicates/setDupes', data.dupes);
				} else {
					this.$store.commit('duplicates/reset');
				}
				this.$root.$emit('setLoading', false);
			};

			this.$store.dispatch('resetStore').then(reader.readAsText(file));
		},
	}
}